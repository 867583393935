<template>
<div class="row tgju-widgets-row dashboard-widget-profile dashboard-widget-profile-edit widget-border-fix widget-padding" style="padding-top: 0 !important;">
    
            <div class="tgju-widget-content w-100">
                <form @submit.prevent="">

                    <v-text-field class="mobile-app-input" label="نام" id="name" v-model="profile.firstname" :maxlength="max"></v-text-field>
                    <v-text-field class="mobile-app-input" label="نام خانوادگی" id="family" v-model="profile.lastname" :maxlength="max"></v-text-field>
                    <data-picker class="mobile-app-input" label="تاریخ تولد" id="birthdate" style="width: 100%; margin-bottom: 10px; margin-top: 10px; display: block;padding: 0 !important;" v-model="birthdate"></data-picker>
                    <v-textarea class="mobile-app-input" label="درباره من" id="bio" v-model="profile.opts.bio"></v-textarea>
                    <v-text-field class="mobile-app-input" label="آدرس پستی" id="inputAddress" v-model="profile.opts.address"></v-text-field>

                    <div class="mobile-app-input mobile-app-form-group">
                        <label for="inputState">استان</label>
                        <select id="inputState" class="form-control t-widget-select widget-select-lg select-set-border" v-model="profile.opts.country" @change="getProvinces()">
                            <option v-for="(value, key) in countries" :key="key" :value="key"> {{ value }} </option>
                        </select>
                    </div>
                    <div class="mobile-app-input mobile-app-form-group">
                        <label for="inputCity">شهر</label>
                        <select id="inputCity" class="form-control t-widget-select widget-select-lg select-set-border" v-model="profile.opts.province">
                            <option v-for="(value, key) in provinces" :key="key" :value="key"> {{ value }} </option>
                        </select>
                    </div>

                    <v-text-field class="mobile-app-input" label="کد پستی" id="inputZip" v-model="profile.opts.postalCode"></v-text-field>
                    <v-text-field class="mobile-app-input" label="ایمیل" id="mail" v-model="profile.email" disabled></v-text-field>
                    <v-text-field class="mobile-app-input" label="تلفن همراه" id="mobile" v-model="profile.mobile" disabled></v-text-field>

                    <v-text-field class="mobile-app-input" label="فیسبوک" id="facebook" v-model="profile.opts.social.facebook"></v-text-field>
                    <v-text-field class="mobile-app-input" label="توییتر" id="twitter" v-model="profile.opts.social.twitter"></v-text-field>
                    <v-text-field class="mobile-app-input" label="لینکدین" id="linkedin" v-model="profile.opts.social.linkedin"></v-text-field>
                    <v-text-field class="mobile-app-input" label="اینستاگرام" id="instagram" v-model="profile.opts.social.instagram"></v-text-field>
                    <v-text-field class="mobile-app-input" label="واتس اپ" id="whatsapp" v-model="profile.opts.social.whatsapp"></v-text-field>
                    <v-text-field class="mobile-app-input" label="تلگرام" id="telegram" v-model="profile.opts.social.telegram"></v-text-field>
                    <v-text-field class="mobile-app-input" label="دیسکورد" id="discord" v-model="profile.opts.social.discord"></v-text-field>

                    <div class="mt-3 mb-3 text-center" style="padding: 0 20px;">
                        <button type="submit" class="w-100 tgju-btn tgju-btn-lg tgju-btn-primary" @click="save()" style="padding: 9px 15px 13px;"><i class="uil uil-save"></i> بروزرسانی</button>
                    </div>
                </form>
            </div>
 
</div>
</template>

<style lang="scss" scoped>
form i {
    margin-left: 5px;
}
</style>

<script>
// این وویو برای ویرایش پروفایل کاربر می باشد
import data_picker from 'vue-persian-datetime-picker';
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

export default {
    name: 'ProfileEditView',
    components: {
        'data-picker': data_picker,
    },
    data: function () {
        return {
            max: 12,
            profile: {
                firstname: undefined,
                lastname: undefined,
                email: undefined,
                mobile: undefined,
                opts: {
                    birthdate: undefined,
                    address: undefined,
                    country: undefined,
                    province: undefined,
                    postalCode: undefined,
                    social: {
                        telegram: undefined,
                        instagram: undefined,
                        facebook: undefined,
                        linkedin: undefined,
                        twitter: undefined,
                        whatsapp: undefined,
                        discord: undefined,
                    },
                    terms: false,
                }
            },
            messengers: {},
            birthdate: undefined,
            countries: {},
            provinces: {},
        }
    },
    mounted() {
        // در هنگام لود شدن صفحه این تابع اجرا می شود
        this.getUser();
        this.getCountries();
    },
    methods: {
        // این متد برای ذخیره سازی اطلاعات پروفایل استفاده میشود
        save() {
            // تعریف ارایه ی از ارور های احتمالی که از سمت سرور برگشت داده می شود
            let server_response = {
                profile_field_empty: 'فیلد های بخش پروفایل الزامی است',
                address_field_empty: 'فیلد های بخش آدرس الزامی است',
                terms_shall_checked: 'قوانین را باید بپذیرید',
                profile_field_to_long: 'کاراکتر وارده شده بیش از حد مجاز می‌باشد',
            };

            this.profile.opts.birthdate = this.birthdate;
            let api_data = {
                data: JSON.stringify(this.profile)
            };
            // ارسال درخواست به سرور
            this.$helpers.makeRequest('POST', '/user/edit', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    // در صورتی که عملیات موفق بود پیام موفقیت به نمایش در آید
                    this.$toast.success({
                        title: 'موفق',
                        message: 'ویرایش پروفایل با موفقیت ثبت شد'
                    });

                    // داده های ویو استور را آپدیت میکنیم با داده های جدید
                    this.$store.dispatch('setUserData', { forced: true });
                    this.$router.push({ name: 'Profile' });
                }

                //  در غیر این صورت پیام خطا را نمایش می دهیم
                if (api_response.status == 400) {
                    this.$toast.error({
                        title: 'خطا',
                        message: server_response[api_response.data.response.message]
                    });
                }
            });
        },
        // این متد برای دریافت استان ها به کار میرود
        getCountries() {
            let api_data = {};
            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/countries', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // در صورتی که درخواست موفق بود
                if (api_response.data.response.status === 'success') {
                    this.countries = api_response.data.response.detail;
                }
            });
        },
        // این متد برای دریافت شهر های مرتبط با استان مورد نظر به کار میرود
        getProvinces(auto) {
            if (auto != 'auto') {
                this.profile.opts.province = undefined;
            }

            let api_data = {
                country_id: this.profile.opts.country,
            };

            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/provinces', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // در صورتی در خواست ای پی آی پیام موفقیت داشت
                if (api_response.data.response.status === 'success') {
                    this.provinces = api_response.data.response.detail;
                }
            });
        },
        // این متد برای دریافت اطلاعات کاربر جاری را به کار می رود
        getUser() {
            let api_data = {};
            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/profile-edit', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // در صورتی در خواست ای پی آی پیام موفقیت داشت
                if (api_response.data.response.status === 'success') {
                    this.messengers = api_response.data.response.detail.messengers;
                    this.profile = api_response.data.response.detail.user;
                    // تبدیل به آبجکت جهت دسترسی بهتر
                    this.profile.opts = this.$helpers.json_pars(this.profile.opts);
                    this.birthdate = this.profile.opts.birthdate;

                    if (this.profile.opts.country > 0) {
                        this.getProvinces('auto');
                    }
                }

            });
        },
        // این متد جهت باز کردن مودال مدیریت مسنجر هایی که به اکانت کار متصل هستند مورد استفاده قرار میگیرد . 
        addMessengerModal() {
            let callbackEvent = this.$router.currentRoute.name + '_MessengerAdd_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MessengerAdd.vue',
                config: {
                    title: 'افزودن پیام رسان',
                    smallModal: true,
                    data: {},
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'AddMessenger') {
                    let messenger = response.data.messenger;
                    // ارسال درخواست ای پی آی
                    this.$helpers.makeRequest('POST', '/user/generate-messenger-token/' + messenger.type).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        // در صورتی در خواست ای پی آی پیام موفقیت داشت
                        if (api_response.status == 200) {
                            let callbackEvent = this.$router.currentRoute.name + '_MessengerToken_' + new Date().getTime();

                            let modal_data = {
                                modal: 'general_modal',
                                callbackEvent,
                                component: 'MessengerToken.vue',
                                config: {
                                    title: 'فعال سازی پیام رسان',
                                    smallModal: true,
                                    data: {
                                        link: messenger.link,
                                        token: api_response.data.token
                                    },
                                }
                            };

                            this.$root.$emit('open_modal', modal_data);
                        }
                    });
                }
            });
        },
        disableMessenger(id) {
            this.$helpers.confirm('هشدار قطع اتصال', {
                message: 'آیا از قطع اتصال اطمینان دارید ؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let api_data = { id: id };
                    // ارسال درخواست ای پی آی
                    this.$helpers.makeRequest('POST', '/user/disable-messenger', api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        // در صورتی در خواست ای پی آی پیام موفقیت داشت
                        if (api_response.data.status) {
                            Vue.prototype.$toast.success({
                                title: 'موفق',
                                message: 'با موفقیت اتصال قطع شد',
                            });
                            // بروز رسانی لیست مسنجر ها و اطلاعات کاربر
                            this.getUser();
                        }
                    });
                }
            });
        },
    },
}
</script>
